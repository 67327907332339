import clsx from 'clsx'
import IntLink from 'components/common/IntLink'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import useSlug from 'hooks/useSlug'
import {
  addPropIf,
  isAbsent,
  isNonEmptyArray,
  isPresent,
  isSame,
  isString,
} from 'lib/utils'
import { useMemo, useRef } from 'react'
import { ICategory, IMenuItem } from 'store/api'

type Props = {
  categoryList: ICategory[]
  leftMenu: IMenuItem[]
}

let HomeCategoryMobileMenu = ({ categoryList, leftMenu }: Props) => {
  const { slug: pageSlug } = useSlug()

  const categories = useMemo(
    () =>
      categoryList.map((c) => {
        const icon =
          leftMenu.find((i) => i.slug === c.slug)?.image ?? ''
        return {
          ...c,
          icon,
        }
      }),
    [categoryList, leftMenu]
  )

  let ref = useRef<HTMLLIElement | null>(null)

  useIsomorphicLayoutEffect(() => {
    if (
      !isString(pageSlug) &&
      isNonEmptyArray(pageSlug) &&
      isNonEmptyArray(leftMenu)
    ) {
      ref.current?.scrollIntoView({ inline: 'center' })
      window.scrollTo({ top: 0 })
    }
  }, [pageSlug])

  let initRender = useRef(true)
  useIsomorphicLayoutEffect(() => {
    if (
      initRender.current &&
      !isString(pageSlug) &&
      isNonEmptyArray(pageSlug) &&
      isNonEmptyArray(leftMenu)
    ) {
      initRender.current = false
      ref.current?.scrollIntoView({ inline: 'center' })
      window.scrollTo({ top: 0 })
    }
  }, [leftMenu])

  return (
    <div className='sticky top-[85px] z-30 lg:hidden w-full bg-white'>
      <ul className='flex overflow-x-auto omit-scrollbar colored-scrollbar px-2 md:px-8 pb-2 gap-2 bg-white'>
        {categories.map(({ id, name, slug }, index) => (
          <li
            key={id}
            {...addPropIf(
              isPresent(pageSlug) && isSame(pageSlug![0], slug),
              'ref',
              ref
            )}
          >
            <IntLink href={`/categories/${slug}`} className=''>
              <div
                className={clsx(
                  'rounded-lg p-2 whitespace-nowrap font-semibold text-xs leading-snug tracking-tight ',
                  {
                    'bg-primary text-dark':
                      (isPresent(pageSlug) &&
                        pageSlug[0]! === slug) ||
                      (isAbsent(pageSlug) && index === 0),
                    'bg-lightGrey text-bodyGrey hover:bg-lightYellow':
                      (isPresent(pageSlug) &&
                        pageSlug[0]! !== slug) ||
                      (isAbsent(pageSlug) && index > 0),
                  }
                )}
              >
                {name}
              </div>
            </IntLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default HomeCategoryMobileMenu
