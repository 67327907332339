import clsx from 'clsx'
import IntLink from 'components/common/IntLink'
import StarIcon from 'components/icons/StarIcon'
import useSlug from 'hooks/useSlug'
import { isDiff, isSame } from 'lib/utils'
import { ICategory, IMenuItem } from 'store/api'

type Props = {
  scrollToProducts?: () => void
  main?: boolean
  leftMenu: IMenuItem[]
  activeCategoryList: ICategory[]
}

const CategoryLeftMenu = ({
  scrollToProducts,
  leftMenu,
  // activeCategoryList,
  main = false,
}: Props) => {
  let { slug } = useSlug()

  // const [expandedCategories, setExpandedCategories] = useState<
  //   number[]
  // >([])

  // const toggleCategory = useCallback((id: number) => {
  //   setExpandedCategories((prev) =>
  //     prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]
  //   )
  // }, [])

  return (
    <div className='hidden lg:block sticky top-[75px] pb-4 pr-4 flex-shrink'>
      <div className='w-[15rem] mb-6'></div>

      <ul className='w-full pr-4 flex-shrink-0 overflow-y-auto max-h-[calc(100vh-124px)] omit-scrollbar-all'>
        {leftMenu?.map((x) => {
          // const category = activeCategoryList?.find(
          //   (c) => c.id === x.category_id ?? -1
          // )
          // const categoryHasSub =
          //   isPresent(category) && category.sub.length > 0
          // const categoryIsExpanded =
          //   categoryHasSub && expandedCategories.includes(category.id)
          return (
            <li key={x.category_id}>
              <div>
                <IntLink
                  scroll={false}
                  href={`/categories/${x.slug}`}
                  className={clsx(
                    'flex items-center space-x-2  px-4 py-3 rounded-md hover:bg-[#ECECEC]',
                    {
                      group: main || isDiff(slug![0], x.slug),
                    }
                  )}
                >
                  {!main && isSame(slug![0], x.slug) && (
                    <StarIcon className='-translate-y-0.5' />
                  )}
                  <div
                    onClick={scrollToProducts}
                    className={clsx(
                      'font-semibold leading-5 text-base2 tracking-tight',
                      {
                        // 'bg-ruby': !main && isSame(slug![0], x.slug),
                        // 'text-dark': main || isDiff(slug![0], x.slug),
                      }
                    )}
                  >
                    {x.name}
                  </div>
                </IntLink>
                {/* {categoryHasSub && (
                  <button
                    onClick={() =>
                      toggleCategory(x.category_id ?? -1)
                    }
                  >
                    {!categoryIsExpanded ? (
                      <ChevronDownIcon
                        className='h-6 w-6 text-bodyGrey text-base2'
                        aria-hidden='true'
                      />
                    ) : (
                      <ChevronUpIcon
                        className='h-6 w-6 text-bodyGrey text-base2'
                        aria-hidden='true'
                      />
                    )}
                  </button>
                )} */}
              </div>

              {/* {categoryHasSub && categoryIsExpanded && (
                <ul className='ml-10 space-y-3 mt-3'>
                  {category.sub.map((s) => (
                    <li
                      key={s.id}
                      className='font-semibold leading-5 tracking-tight text-bodyGrey'
                    >
                      <IntLink
                        scroll={false}
                        href={`/categories/${x.slug}/${s.slug}`}
                        className={clsx({
                          'text-dark':
                            !main &&
                            isSame(slug![1], s.slug) &&
                            isSame(slug![0], x.slug),
                        })}
                      >
                        {s.name}
                      </IntLink>
                    </li>
                  ))}
                </ul>
              )} */}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default CategoryLeftMenu
