import { Dialog, Transition } from '@headlessui/react'
import { MenuIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import ExtLink from 'components/common/ExtLink'
import IntLink from 'components/common/IntLink'
import Button from 'components/forms/Button'
import DiscountIcon from 'components/icons/Discount2'
import UserCircleIcon from 'components/icons/UserCircleIcon'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import useSlug from 'hooks/useSlug'
import { addPropIf, isAbsent, isPresent, isSame } from 'lib/utils'

import { useRouter } from 'next/router'
import { Fragment, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { IMenuItem, selectCities } from 'store/api'
import { selectCity, selectUserToken } from 'store/auth'

type Props = {
  drawerMenu?: IMenuItem[]
  leftMenu?: IMenuItem[]
  setIsOpenCb: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isOpen: boolean
}

let HeaderMobileMenu = ({
  drawerMenu,
  leftMenu,
  setIsOpenCb,
  setIsOpen,
  isOpen,
}: Props) => {
  let {
    router: { asPath },
    queryLng,
  } = useSlug()
  let router = useRouter()

  let [isCategoryListOpen, setIsCategoryListOpen] = useState(false)

  let activeCities = useSelector(selectCities(queryLng))
  let _city = useSelector(selectCity)
  let city = isPresent(_city)
    ? activeCities.find((c) => c.code === _city)
    : null

  useIsomorphicLayoutEffect(() => {
    setIsCategoryListOpen(false)
  }, [isOpen])

  useIsomorphicLayoutEffect(() => {
    setIsOpen(false)
  }, [asPath])

  let userToken = useSelector(selectUserToken)

  let handleClick = useCallback(() => {
    isAbsent(userToken) && setIsOpenCb((prev) => !prev)
  }, [userToken, setIsOpenCb])

  const onDrawerMenuItemClick = useCallback(
    (e, path: string) => {
      if (isSame(asPath, path)) {
        setIsOpen(false)
        e.preventDefault()
        e.stopPropagation()
      }
    },
    [asPath]
  )

  return (
    <>
      <div className='flex'>
        <button
          type='button'
          aria-label='Меню'
          className='-m-2 p-3.5 outline-none focus:outline-none rounded-full bg-lightGrey'
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {!isOpen ? (
            <MenuIcon className='h-5 w-5' aria-hidden='true' />
          ) : (
            <XIcon className='h-5 w-5' aria-hidden='true' />
          )}
        </button>
      </div>

      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 flex z-30 lg:hidden select-none'
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-dark bg-opacity-75 absolute top-[85px] left-0' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='h-full absolute top-[85px] left-0 sm:max-w-xs w-full text-base2 leading-snug bg-white shadow-xl pb-4 flex flex-col overflow-y-auto'>
              <ul className='pt-5 px-5 space-y-6'>
                <li
                  className={clsx(
                    'overflow-hidden transition-max-height flow-root',
                    {
                      'max-h-full': isCategoryListOpen,
                      'max-h-4 pb-5': !isCategoryListOpen,
                    }
                  )}
                >
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault()
                      setIsCategoryListOpen((prev) => !prev)
                    }}
                    className={clsx(
                      '-m-2 p-2 block tracking-tight outline-none focus:outline-none',
                      {
                        'text-accent':
                          router.pathname.startsWith('/categories') ||
                          router.pathname.startsWith('/product'),
                      }
                    )}
                  >
                    Меню
                  </button>

                  <ul className='ml-0.5 pl-4'>
                    {leftMenu?.map(({ slug, name }) => (
                      <li key={slug}>
                        <IntLink
                          href={`/categories/${slug}`}
                          className='-m-2 mt-3 p-2 block tracking-tight'
                        >
                          {name}
                        </IntLink>
                      </li>
                    ))}
                  </ul>
                </li>

                {drawerMenu?.map((x) => (
                  <li key={x.path} className='flow-root'>
                    <span
                      className={clsx(
                        '-m-2 p-2 block tracking-tight',
                        {
                          'text-accent': isSame(
                            router.pathname,
                            x.path
                          ),
                        }
                      )}
                    >
                      {x.path.startsWith('http') ? (
                        <ExtLink href={x.path}>{x.name}</ExtLink>
                      ) : (
                        <IntLink
                          href={x.path}
                          className='flex items-center'
                          onClick={(e) =>
                            onDrawerMenuItemClick(e, x.path)
                          }
                        >
                          {x.name}
                          {isSame(x.path, '/aktsii') && (
                            <div className='ml-2'>
                              <DiscountIcon className='h-5 w-5' />
                            </div>
                          )}
                        </IntLink>
                      )}
                    </span>
                  </li>
                ))}
              </ul>

              {isPresent(city) && (
                <div className='pt-12 px-5 text-dark text-xl'>
                  {city.phones.map((phone) => {
                    return (
                      <div
                        key={phone}
                        className='leading-relaxed font-semibold tracking-tighter'
                      >
                        <a
                          key={phone}
                          href={`tel:+${phone.replace(/[\s-]/g, '')}`}
                        >
                          {phone}
                        </a>
                      </div>
                    )
                  })}
                </div>
              )}
              <Button
                {...addPropIf(isPresent(userToken), 'as', 'a')}
                {...addPropIf(
                  isPresent(userToken),
                  'href',
                  '/account'
                )}
                aria-label={
                  isPresent(userToken) ? 'Личный кабинет' : 'Войти'
                }
                className={clsx('-m-2 pt-8 group px-5', {
                  'lg:hidden': isPresent(userToken),
                })}
                onClick={handleClick}
              >
                <div className='flex gap-2 items-center'>
                  <UserCircleIcon
                    className='h-6 w-6 text-accent'
                    aria-hidden='true'
                  />
                  <span className='text-base2 leadning-none translate-y-0.5'>
                    {isPresent(userToken)
                      ? 'Личный кабинет'
                      : 'Войти'}
                  </span>
                </div>
              </Button>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default HeaderMobileMenu
