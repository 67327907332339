import ExtLink from 'components/common/ExtLink'
import IntLink from 'components/common/IntLink'
import TelegramIcon from 'components/icons/TelegramIcon'
import VkIcon from 'components/icons/VkIcon'
import WhatsAppIcon2 from 'components/icons/WhatsAppIcon2'
import FooterLogo from 'components/logos/FooterLogo'
import {
  ADDRESS,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  FOOTER_ABOUT_PAGE_LIST,
  TELEGRAM_LINK,
  VK_LINK,
} from 'config/constants'
import useMenus from 'hooks/useMenus'
import { getServices } from 'lib/getServices'
import { isAbsent, isNonEmptyArray, isPresent } from 'lib/utils'
import Image from 'next/image'
import appStore from 'public/img/App_Store.png'
import googlePlay from 'public/img/Google_Play.png'
import img1 from 'public/img/footer1.png'
import img2 from 'public/img/footer2.png'
import img3 from 'public/img/footer3.png'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCities, type IService } from 'store/api'

const FooterDsk = () => {
  const cities = useSelector(selectCities('ru'))

  let { footerMenu } = useMenus()

  const [services, setServices] = useState<IService[]>([])

  const defaultCity = useMemo(
    () => (isNonEmptyArray(cities) ? cities[0] : null),
    [cities]
  )

  useEffect(() => {
    void (async () => {
      const servicesData = await getServices('ru')

      if (isPresent(servicesData)) {
        setServices(servicesData.services)
      }
    })()
  }, [])

  // const openHours = useMemo(
  //   () =>
  //     `Ежедневно ${defaultCity?.call_time.begin || '08:00'} - ${
  //       defaultCity?.call_time.end || '20:00'
  //     }`,
  //   [defaultCity]
  // )

  if (isAbsent(defaultCity)) return null

  const menuComponent = (
    <div className='hidden xl:flex flex-col'>
      <div className='text-sm mb-6 !leading-6 font-bold text-dark uppercase'>
        Меню
      </div>

      {footerMenu.map(({ name, slug }) => (
        <div key={slug}>
          <IntLink
            href={`/categories/${slug}`}
            className='line-clamp-1 text-base2 !leading-7 text-mediumDark'
          >
            {name}
          </IntLink>
        </div>
      ))}
    </div>
  )

  const cateringComponent = (
    <div className='flex flex-col'>
      <div className='text-sm mb-6 !leading-6 font-bold text-dark uppercase'>
        Кейтеринг
      </div>

      {services.map(({ name, slug }) => (
        <div key={slug}>
          <IntLink
            href={`/catering/${slug}`}
            className='line-clamp-1 text-base2 !leading-7 text-mediumDark'
          >
            {name}
          </IntLink>
        </div>
      ))}
    </div>
  )

  const aboutComponent = (
    <div className='flex flex-col'>
      <div className='text-sm mb-6 !leading-6 font-bold text-dark uppercase'>
        О нас
      </div>

      {FOOTER_ABOUT_PAGE_LIST.map((x) => (
        <div key={x.href}>
          <IntLink
            shallow={!x.deep}
            href={x.href}
            className='text-base2 !leading-6 text-mediumDark'
          >
            {x.name}
          </IntLink>
        </div>
      ))}
    </div>
  )

  return (
    <footer className='bg-lightGrey custom-px pt-2 pb-4 bg-spoons-footer-pattern'>
      <div className='grid grid-cols-3 xl:grid-cols-4 justify-between gap-12 lg:gap-24 pb-4 mt-8 pt-3'>
        <div className='flex-shrink-0'>
          <FooterLogo className='h-[116px] w-[178px]' />

          <div className='mt-5'>
            <a
              className='whitespace-nowrap text-mediumDark tracking-[-0.0272em;] text-xl font-bold !leading-6 '
              href={`tel:+${
                defaultCity?.phones[0]?.replace(/\D/g, '') ||
                CONTACT_PHONE.replace(/\D/g, '')
              }`}
            >
              {defaultCity?.phones[0] || CONTACT_PHONE}
            </a>
          </div>

          <div className='flex flex-col row-start-1 col-span-2 md:col-span-1 mt-4'>
            <div className='text-sm !leading-7 font-bold text-dark inline-flex items-center gap-2'>
              Адрес:
            </div>
            <div className='text-sm md:text-base2 !leading-6 text-mediumDark'>
              {defaultCity?.location?.address || ADDRESS}
            </div>
          </div>

          <div className='mt-4'>
            <div className='font-bold text-sm text-dark !leading-7'>
              Режим работы:
            </div>

            <div className='flex flex-row gap-2.5'>
              <div className='text-gray-500 text-xs md:text-base2 !leading-6'>
                Пн-сб
                <br />
                Вс
              </div>
              <div className='text-mediumDark text-xs md:text-base2 !leading-6'>
                8:00–20:00
                <br />
                9:00–20:00
              </div>
            </div>
          </div>

          <div className='row-start-3 col-start-1 mt-4'>
            <div className='font-bold text-sm text-dark !leading-6'>
              E-mail:{' '}
            </div>
            <a
              className='text-mediumDark text-xs md:text-base2 !leading-6 underline'
              href={`mailto:${CONTACT_EMAIL}`}
            >
              {CONTACT_EMAIL}
            </a>
          </div>
        </div>
        {menuComponent}
        {cateringComponent}
        {aboutComponent}
      </div>

      <div className='flex flex-row justify-between items-center gap-24 mt-4 border-t pt-8'>
        <div className='flex flex-row justify-around lg:justify-center items-center lg:gap-12'>
          <iframe
            src='https://yandex.ru/sprav/widget/rating-badge/1286099425?type=rating'
            width='150'
            height='50'
            frameBorder='0'
          ></iframe>

          <iframe
            src='/misc/gis.html'
            frameBorder='0'
            width='155'
            height='50'
            sandbox='allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation'
          ></iframe>
        </div>

        <div className='flex flex-row justify-center items-center gap-12'>
          <Image
            src={img1}
            layout='fixed'
            objectFit='contain'
            width='56px'
            height='23px'
          />
          <Image
            src={img2}
            layout='fixed'
            objectFit='cover'
            width='54px'
            height='36px'
          />
          <Image
            src={img3}
            layout='fixed'
            objectFit='contain'
            width='65px'
            height='23px'
          />
        </div>
      </div>

      <div className='lg:hidden my-9 mx-auto text-center text-dark text-2xs md:text-xs md:text-center'>
        {defaultCity.footer?.footerMsg || (
          <>
            Moscow Food - это более 15 лет успешной работы в сфере
            общественного питания. Профессиональная команда в сфере
            кейтеринговых и ивент услуг и разнообразное меню. Команда
            профессиональных поваров готова предложить Вам широкий
            ассортимент блюд на любой вкус — от классической
            европейской кухни до экзотических блюд.
          </>
        )}
      </div>

      <div className='flex flex-row mt-9 justify-between gap-36 items-center'>
        <div className='w-[14%] flex-shrink-0 flex flex-row gap-4'>
          <div className='flex flex-row gap-3 items-center'>
            <ExtLink
              className='leading-none'
              href='https://play.google.com/store/apps/details?id=com.moscowfood'
            >
              <Image
                src={googlePlay}
                layout='fixed'
                objectFit='contain'
                width='120px'
                height='40px'
                title='Google Play'
                alt='Google Play'
              />
            </ExtLink>

            <ExtLink
              className='leading-none'
              href='https://apps.apple.com/ru/app/moscowfood/id6447418248'
            >
              <Image
                src={appStore}
                layout='fixed'
                objectFit='contain'
                width='120px'
                height='40px'
                title='App Store'
                alt='App Store'
              />
            </ExtLink>
          </div>
        </div>

        <div className='hidden lg:block mx-auto text-center text-dark text-xs md:text-center'>
          {defaultCity.footer?.footerMsg || (
            <>
              Moscow Food - это более 15 лет успешной работы в сфере
              общественного питания. Профессиональная команда в сфере
              кейтеринговых и ивент услуг и разнообразное меню.
              Команда профессиональных поваров готова предложить Вам
              широкий ассортимент блюд на любой вкус — от классической
              европейской кухни до экзотических блюд.
            </>
          )}
        </div>

        <div className='w-[14%] flex-shrink-0 flex justify-end flex-row items-center'>
          <div className='flex gap-3 items-center'>
            <ExtLink title='ВКонтакте' href={VK_LINK}>
              <VkIcon className='w-8 h-8 rounded hover:text-darkYellow' />
            </ExtLink>
            <ExtLink title='Телеграм' href={TELEGRAM_LINK}>
              <TelegramIcon className='w-8 h-8 rounded hover:text-darkYellow' />
            </ExtLink>
            <ExtLink
              title='WhatsApp'
              href={`https://wa.me/+79804002225`}
              className='flex flex-col items-center gap-2.5'
            >
              <WhatsAppIcon2 className='w-8 h-8 rounded hover:text-darkYellow' />
            </ExtLink>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterDsk
