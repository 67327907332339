import { BASE_URL } from 'config/constants'
import {
  IGetServicesResult,
  IGetServiceDetailsResult,
} from 'store/api'
import fetcher from './fetcher'

export let getServices = async (lng: string) => {
  let getServicesResult = await fetcher({
    url: `${BASE_URL}/v1/service/list`,
    headers: {
      lng,
    },
  })

  return getServicesResult.error
    ? ({} as IGetServicesResult)
    : (getServicesResult.data as IGetServicesResult)
}

export const getServiceDetails = async (
  lng: string,
  slug: string
) => {
  let getServiceResult = await fetcher({
    url: `${BASE_URL}/v1/service/view/${slug}`,
    headers: {
      lng,
    },
  })

  return getServiceResult.error
    ? ({} as IGetServiceDetailsResult)
    : (getServiceResult.data as IGetServiceDetailsResult)
}
