import {
  isNonEmptyArray,
  isNonEmptyString,
  isPresent,
  isSame,
  toKeys,
} from 'lib/utils'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  IMenuItem,
  selectCategoryList,
  selectProductList,
} from 'store/api'
import api from 'store/api/reducer'
import { selectIsBrowser } from 'store/ui'
import useSlug from './useSlug'

let useMenus = (menusSSR?: Record<string, IMenuItem[]>) => {
  let { queryLng } = useSlug()
  let isBrowser = useSelector(selectIsBrowser)

  let { data: menus } = api.endpoints.getMenus.useQueryState(queryLng)

  let [menu, drawerMenu, leftMenu, footerMenu] = useMemo(
    () => [
      (isNonEmptyArray(menus?.['top'])
        ? menus?.['top'] ?? []
        : menusSSR?.['top'] ?? []
      )?.filter((x) => isNonEmptyString(x.path)),
      (isNonEmptyArray(menus?.['drawer'])
        ? menus?.['drawer'] ?? []
        : menusSSR?.['drawer'] ?? []
      )?.filter((x) => isNonEmptyString(x.path)),
      (isNonEmptyArray(menus?.['left'])
        ? menus?.['left'] ?? []
        : menusSSR?.['left'] ?? []
      )?.filter((x) => isNonEmptyString(x.path)),
      (isNonEmptyArray(menus?.['footer'])
        ? menus?.['footer'] ?? []
        : menusSSR?.['footer'] ?? []
      )?.filter((x) => isNonEmptyString(x.path)),
    ],
    [menus, menusSSR]
  )

  let products = useSelector(selectProductList(queryLng))
  let categoryList = useSelector(selectCategoryList(queryLng))

  let activeCategoryIdList = useMemo(
    () =>
      toKeys(products)
        .map((id) =>
          categoryList?.find((c) => isSame(c.id, Number(id)))
        )
        .filter((x) => isPresent(x))
        .map((x) => x!.id),
    [categoryList, products]
  )

  let _leftMenu = useMemo(
    () =>
      isBrowser
        ? leftMenu
            .filter((x) => isPresent(x.category_id))
            .filter((x) =>
              activeCategoryIdList.includes(x.category_id!)
            )
        : leftMenu,
    [activeCategoryIdList, isBrowser, leftMenu]
  )

  return {
    menu,
    drawerMenu,
    leftMenu: _leftMenu,
    footerMenu,
  }
}

export default useMenus
