import IntLink from 'components/common/IntLink'
import useMenus from 'hooks/useMenus'
import useSlug from 'hooks/useSlug'
import { isAbsent, isNonEmptyString, isPresent } from 'lib/utils'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IMenuItem } from 'store/api'
import { selectIsLg } from 'store/ui'

const staticPages: [string, string][] = [
  ['/account/orders', 'История заказов'],
  ['/account', 'Личные данные'],
  ['/cart', 'Оформление'],
  ['/checkout', 'Оформление'],
  ['/payment', 'Оплата'],
  ['/auth/confirm', 'Подтверждение email'],
  // ['/service', 'Кейтеринг'],
  // ['/catering', 'Кейтеринг'],
]

type Props = {
  menusSSR: Record<string, IMenuItem[]>
}
const BreadcrumbMenu = ({ menusSSR }: Props) => {
  let { menu } = useMenus(menusSSR)
  let {
    router: { pathname },
  } = useSlug()
  const isLg = useSelector(selectIsLg)

  let currentPageName = useMemo(() => {
    let pageName = menu?.find((x) =>
      pathname.startsWith(x.path)
    )?.name
    if (isAbsent(pageName)) {
      const page = staticPages.find(
        ([path, _]) => pathname.indexOf(path) > -1
      )
      pageName = isPresent(page) ? page[1] : ''
    }
    return pageName
  }, [menu, pathname])

  let isChild = useMemo(
    () => pathname.indexOf('[slug]') > -1,
    [pathname]
  )
  let parentPath = useMemo(
    () =>
      isChild ? pathname.split('/').slice(0, -1).join('/') : pathname,
    [isChild, pathname]
  )

  return (
    <>
      {isLg && (
        <div className='hidden lg:block py-4 pr-0 custom-plx'>
          <div className='w-full'>
            <div className='hidden lg:block text-sm2 leading-tight tracking-tight'>
              <IntLink
                className='text-dark underline hover:text-accent'
                href='/'
              >
                Главная
              </IntLink>
              {isChild && isNonEmptyString(currentPageName) && (
                <>
                  {' — '}
                  <IntLink
                    className='text-dark underline hover:text-accent'
                    href={parentPath}
                  >
                    {currentPageName}
                  </IntLink>
                </>
              )}
              {!isChild && isNonEmptyString(currentPageName) && (
                <span className='text-greyA1'>{` — ${currentPageName}`}</span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BreadcrumbMenu
